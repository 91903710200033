﻿(function ($) {
    $.fn.rangeSlider = function (options) {
        // Valores por defecto del slider
        var defaults = {
            min: 0,
            max: 10,
            smallStep: 1,
            largeStep: 2,
            change: function () { },
            slide: function () { }
        };

        // Combinar valores por defecto con opciones proporcionadas por el usuario
        var settings = $.extend({}, defaults, options);

        return this.each(function () {
            var $slider = $(this);
            $slider.addClass("pg-slider");

            // Crear la estructura HTML dentro del contenedor usando clases en lugar de IDs
            $slider.html(`
                <div class="slider-thumb thumb-left"></div>
                <div class="slider-thumb thumb-right"></div>
                <div class="slider-range"></div>
            `);

            // Añadir las etiquetas de valor fuera del contenedor del slider
            $slider.after(`
                <div class="slider-labels">
                    <span class="slider-label min-value">${settings.min}</span>
                    <span class="slider-label max-value">${settings.max}</span>
                </div>
            `);

            var $thumbLeft = $slider.find('.thumb-left');
            var $thumbRight = $slider.find('.thumb-right');
            var $range = $slider.find('.slider-range');
            var $minValueLabel = $slider.next('.slider-labels').find('.min-value');
            var $maxValueLabel = $slider.next('.slider-labels').find('.max-value');
            var maxWidth = $slider.width();
            var minValue = settings.min;
            var maxValue = settings.max;

            // Almacenar los valores actuales
            var currentValues = [settings.min, settings.max];

            function positionToValue(position) {
                var ratio = position / (maxWidth - $thumbLeft.width());
                var value = minValue + (ratio * (maxValue - minValue));
                return Math.round(value / settings.smallStep) * settings.smallStep;
            }

            function valueToPosition(value) {
                var ratio = (value - minValue) / (maxValue - minValue);
                return ratio * (maxWidth - $thumbLeft.width());
            }

            function updateRange() {
                var leftValue = positionToValue($thumbLeft.position().left);
                var rightValue = positionToValue($thumbRight.position().left);
                $range.css({
                    left: $thumbLeft.position().left,
                    width: $thumbRight.position().left - $thumbLeft.position().left
                });
                $minValueLabel.text(leftValue);
                $maxValueLabel.text(rightValue);
                settings.slide({ value: [leftValue, rightValue] });
                currentValues = [leftValue, rightValue];
                $slider.data('currentValues', currentValues);
            }

            function setPosition($thumb, position) {
                position = Math.max(0, Math.min(position, maxWidth - $thumb.width()));
                $thumb.css('left', position);
            }

            function initializePositions() {
                setPosition($thumbLeft, 0);
                setPosition($thumbRight, maxWidth - $thumbRight.width());
                $minValueLabel.text(minValue);
                $maxValueLabel.text(maxValue);
                currentValues = [minValue, maxValue];
                $slider.data('currentValues', currentValues);
                updateRange(); // Llamar a updateRange para pintar el slider-range inicialmente
            }

            // Inicializar posiciones sin cálculos
            initializePositions();

            $thumbLeft.on('mousedown', function (event) {
                event.preventDefault();
                $(document).on('mousemove.thumbLeft', function (event) {
                    var newPosition = event.pageX - $slider.offset().left;
                    newPosition = Math.max(0, Math.min(newPosition, $thumbRight.position().left - $thumbLeft.width()));
                    setPosition($thumbLeft, newPosition);
                    updateRange();
                }).on('mouseup.thumbLeft', function () {
                    $(document).off('.thumbLeft');
                    settings.change({ value: [positionToValue($thumbLeft.position().left), positionToValue($thumbRight.position().left)] });
                });
            });

            $thumbRight.on('mousedown', function (event) {
                event.preventDefault();
                $(document).on('mousemove.thumbRight', function (event) {
                    var newPosition = event.pageX - $slider.offset().left;
                    newPosition = Math.max($thumbLeft.position().left + $thumbRight.width(), Math.min(newPosition, maxWidth - $thumbRight.width()));
                    setPosition($thumbRight, newPosition);
                    updateRange();
                }).on('mouseup.thumbRight', function () {
                    $(document).off('.thumbRight');
                    settings.change({ value: [positionToValue($thumbLeft.position().left), positionToValue($thumbRight.position().left)] });
                });
            });

            // Almacenar las configuraciones en el elemento para uso futuro
            $slider.data('rangeSliderSettings', settings);
        });
    };

    // Método para obtener los valores actuales
    $.fn.rangeSlider.getValues = function ($slider) {
        return $slider.data('currentValues');
    };

    // Método para actualizar los parámetros de min y max reinicializando el componente
    $.fn.resetRangeSlider = function (options) {
        return this.each(function () {
            var $slider = $(this);

            // Remover las etiquetas de valores
            $slider.next('.slider-labels').remove();

            // Remover el slider actual
            $slider.empty();

            // Inicializar el slider nuevamente con las nuevas opciones
            $slider.rangeSlider(options);
        });
    };
}(jQuery));

// Ejemplo de inicialización
$("#range-slider-1").resetRangeSlider({
    min: 100,
    max: 10000,
    smallStep: 10,
    largeStep: 500
});

$("#range-slider-2").resetRangeSlider({
    min: 50,
    max: 5000,
    smallStep: 5,
    largeStep: 100
});
