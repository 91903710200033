/**
 * Usage: 
  import http from './path-to-http-class'; // Adjust the path according to your file structure

 // Create an Http instance with default axios configuration
 const httpClient = http();

 // Example usage for GET request
 httpClient.get('https://api.example.com/data')
 .then(data => {
 console.log('GET request data:', data);
 })
 .catch(error => {
 console.error('GET request error:', error);
 });
 */
import axios from 'axios'
class Http{
    
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async get(url, config = {}) {
        try {
            const response = await this.httpClient.get(url, config);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async post(url, data = {}, config = {}) {
        try {
            const response = await this.httpClient.post(url, data, config);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }
    async put(url, data = {}, config = {}) {
        try {
            const response = await this.httpClient.put(url, data, config);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async delete(url, config = {}) {
        try {
            const response = await this.httpClient.delete(url, config);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    handleError(error) {
        // Uniform error handling logic
        console.error('API call failed: ', error);
        throw error;
    }
}

const http = (config = {}) => {
    const httpClient = axios.create(config);
    return new Http(httpClient);
};
export default http