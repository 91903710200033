import Alert from "./alert";

export default function form(formElement) {
    const data = new FormData(formElement);
    Validator.useLang('es');

    return {
        serialize: () => {
            const dataObject = {};
            data.forEach((value, name) => {
                const inputElement = formElement.querySelector(`[name="${name}"]`);
                if (inputElement && inputElement.hasAttribute('data-numeric')) {
                    let decimals = parseInt(inputElement.getAttribute('data-decimals')) || 0;
                    value = new Str(value).decimal(decimals);

                    if (value === NaN || value === 'NaN')
                        value = null;
                }

                dataObject[name] = value;
            });
            return dataObject;
        },
        validate: (formData, rules, messages = {}) => {

            var validator = new Validator(formData, rules, messages);
            validator.check();

            if (validator.fails()) {
                console.log(validator.errors.all());
                mostrarValidacionModelo(validator.errors.all())
            }

            return validator.passes();
        },
        submit: (dataObject) => {
            return new Promise(async (resolve, reject) => {
                dataObject = dataObject ? dataObject : this.serialize();

                try {
                    loading().show();

                    const response = await http().post(formElement.action, dataObject, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

                    Alert.success(_t("master.guardado_exitoso"));
                    resolve(response);

                } catch (error) {

                    if (error.code === "ERR_BAD_REQUEST" && error.response.data?.errors)
                        mostrarValidacionModelo(error.response.data.errors);
                    else if (error.code === 'ERR_NETWORK')
                        Alert.error(error.message);
                    else
                        Alert.error(error.response.data?.message || _t("master.ocurrio_un_error"));

                    reject(error);

                } finally {
                    loading().hide();
                }
            });
        }
    };
}

function mostrarValidacionModelo(errors) {
    $('[data-valmsg-for]').text('');

    for (let key in errors) {
        if (errors.hasOwnProperty(key)) {
            let fieldName = key;
            let errorMessage = errors[key][0];
            mostrarErrorDeValidacion(fieldName, errorMessage || "Invalid");
        }
    }
}

function mostrarErrorDeValidacion(fieldName, errorMessage) {
    const validationElement = $('[data-valmsg-for="' + fieldName + '"]');
    if (validationElement.length) {
        validationElement.text(errorMessage).removeClass('field-validation-valid').addClass('field-validation-error');
    }
    else {
        Alert.warning(`${fieldName}: ${errorMessage}`);
    }
}
