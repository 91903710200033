﻿(function ($) {
    $.fn.dynamicForm = function (formData, settings) {
        // Opciones por defecto que pueden ser sobrescritas por el usuario
        const defaultSettings = {
            onSubmit: null // Callback para el envío de los datos
        };

        // Almacenar las instancias de los formularios con identificadores únicos
        const forms = [];

        //Almacena los inputs que estarán ocultos al inicializar el formulario
        let initItemsHiden = [];

        // Función para inicializar el canvas de firma con Kendo Drawing
        function initSignatureCanvas() {
            var draw = kendo.drawing;
            var Path = draw.Path;

            var surface = draw.Surface.create($("#firmaCanvas"), {
                type: "svg"
            });

            var drawing = false;
            var currentPath;
            var offset = surface.element.offset();

            $("#firma-container")
                .on("mousedown", function (e) {
                    drawing = true;
                    var offset = $(this).offset();
                    currentPath = new Path({
                        stroke: {
                            color: '#000',
                            width: 2,
                            lineCap: "round",
                            lineJoin: "round"
                        }
                    });
                    surface.draw(currentPath);
                    currentPath.moveTo(e.pageX - offset.left, e.pageY - offset.top);
                })
                .on("mousemove", function (e) {
                    if (drawing) {
                        var offset = $(this).offset();
                        currentPath.lineTo(e.pageX - offset.left, e.pageY - offset.top);
                        surface.draw(currentPath);
                    }
                })
                .on("mouseup", function () {
                    drawing = false;
                })
                .on("mouseleave", function () {
                    drawing = false;
                });

            $("#resetSignature").on("click", function () {
                surface.clear(); // Limpiar el canvas
            });
        }

        function initMapCanvas() {
            const mapElement = $('<div id="map"></div>').appendTo('#mapaModal .modal-body');
            const map = mapElement.kendoMap({
                center: [0, 0],
                zoom: 3,
                layers: [{
                    type: "tile",
                    urlTemplate: "https://tile.openstreetmap.org/#= zoom #/#= x #/#= y #.png"
                }]
            }).data("kendoMap");

            let markers = [];

            // Evento para capturar la ubicación seleccionada
            map.bind("click", function (e) {
                const location = map.eventToLocation(e.originalEvent);

                // Limpiar el marcador existente
                if (markers.length > 0) {
                    map.markers.clear();
                    markers = [];
                }

                // Agregar un nuevo marcador
                const marker = {
                    location: [location.lat, location.lng],
                    shape: "pinTarget",
                    tooltip: {
                        content: "Lat: " + location.lat + ", Lng: " + location.lng
                    }
                };
                map.markers.add(marker);
                markers.push(marker);

                // Guardar la latitud y longitud
                $("#mapaModal").data("lat", location.lat);
                $("#mapaModal").data("lng", location.lng);
            });

            $("#saveLocation").on("click", function () {
                const lat = $("#mapaModal").data("lat");
                const lng = $("#mapaModal").data("lng");
                //console.log("Latitud: " + lat + ", Longitud: " + lng);
                // Guardar lat y lng en el campo correspondiente
                $("#mapaModal").css("display", "none");
            });
        }

        function getSecciones(formData) {
            const secciones = {};
            formData.forEach(pregunta => {
                if (!pregunta.seccionTabla && pregunta.seccionActiva) {
                    if (!secciones[pregunta.seccionClave]) {
                        secciones[pregunta.seccionClave] = {
                            nombre: pregunta.seccionNombre,
                            preguntas: [],
                            seccionClave: pregunta.seccionClave
                        };
                    }
                    secciones[pregunta.seccionClave].preguntas.push(pregunta);
                }
            });
            return Object.values(secciones);
        }

        function handleAction(actionType, target) {
            // Divide el parámetro target en un array
            try {
                const targets = target.split(',');

                // Itera sobre cada elemento en el array
                targets.forEach(singleTarget => {
                    // Elimina espacios en blanco de los extremos
                    singleTarget = singleTarget.trim();

                    // Realiza la acción correspondiente para cada target individual
                    switch (actionType) {
                        case 'esconder_preguntas':
                            $(`[data-container-for="${singleTarget}"]`).closest('.k-form-field').hide();
                            break;
                        case 'mostrar_preguntas':
                            $(`[data-container-for="${singleTarget}"]`).closest('.k-form-field').show();
                            break;
                        case 'esconder_secciones':
                            $(`[data-section="${singleTarget}"]`).hide();
                            break;
                        case 'mostrar_secciones':
                            $(`[data-section="${singleTarget}"]`).show();
                            break;
                        case 'mandarNotificacion':
                            Alert.warning(singleTarget);
                            break;
                        case 'abrirFirmaModal':
                            $('#firmaModal').modal('show');
                            initSignatureCanvas();
                            break;
                        case 'abrirMapaModal':
                            $('#mapaModal').modal('show');
                            break;
                        case 'abrirSeccionModal':
                            $('#seccionModal').modal('show');
                            $('#seccionModal .modal-body #dynamicFormSubseccion').empty();
                            break;
                        default:
                            console.warn(`Acción no soportada: ${actionType}`);
                    }
                });
            } catch (e) {
                console.warn(`Acción no soportada: ${actionType}`);
            }
        }

        function markSectionAsComplete(tab) {
            $(".tab-container").find("li a").each(function () {
                var $me = $(this);
                var href = $me.attr("href");
                var liParent = $me.parents("li:first");
                var idLiParent = $(liParent).attr("id");

                if (href === tab || href === '#' + tab || idLiParent === tab) {
                    $(liParent).addClass('item-complete');
                }
            });
        }

        // Función para verificar si los formularios están completos y son válidos
        function checkIfSectionComplete(form) {
            var isValid = true;
            form.find('[required]').each(function () {
                let isFieldVisible = $(`[data-container-for="${this.id}"]`).closest('.k-form-field').is(':visible');

                if (!this.value.trim() && isFieldVisible) {
                    isValid = false;
                    return false;
                }
            });
            return isValid;
        }

        function createTable(pregunta, isCheckbox) {
            var data = getJsonDataTableData(pregunta);
            var table = $('<table class="table table-bordered"></table>');

            // Crear encabezado de la tabla
            var thead = $('<thead></thead>');
            var headerRow = $('<tr></tr>');
            headerRow.append('<th></th>'); // Celda vacía para la esquina superior izquierda

            data.Columnas.forEach(function (columna) {
                headerRow.append('<th>' + columna.txt + '</th>');
            });
            thead.append(headerRow);
            table.append(thead);

            // Crear cuerpo de la tabla
            var tbody = $('<tbody></tbody>');
            data.Filas.forEach(function (fila) {
                var row = $('<tr></tr>');
                row.append('<td>' + fila.txt + '</td>');

                data.Columnas.forEach(function (columna) {
                    var cell = $('<td></td>');
                    var inputType = isCheckbox ? 'checkbox' : 'radio';
                    var input = $('<input type="' + inputType + '" class=""/>')
                        .attr('name', isCheckbox ? fila.clave + '_' + columna.clave : fila.clave)
                        .attr('value', columna.clave);
                    cell.append(input);
                    row.append(cell);
                });
                tbody.append(row);
            });
            table.append(tbody);

            // Insertar la tabla en el contenedor correspondiente
            setTimeout(function () {
                $('[data-container-for="' + pregunta.PreguntaClave + '"]').append(table)
            }, 1500);
        }

        // Crear contenido de las preguntas dentro de la sección
        function createQuestions(preguntas) {
            const items = [];
            preguntas.forEach(pregunta => {
                let item = {
                    field: pregunta.preguntaClave,
                    label: pregunta.tituloPregunta,
                    attributes: { "data-field": pregunta.preguntaClave, "data-formula": pregunta.formula }
                };

                /*FUNCION*/

                switch (true) {
                    case pregunta.tipoDatoValidacion === 'text' && pregunta.tipo === 'text':
                        item.editor = "TextBox";
                        pregunta.kendoEditor = "TextBox";
                        item.editorOptions = {
                            readonly: pregunta.formula !== null,
                        };
                        break;
                    case pregunta.tipoDatoValidacion === 'text' && pregunta.tipo === 'omultiple2':
                        item.editor = "CheckBoxGroup";
                        pregunta.kendoEditor = "CheckBoxGroup";
                        item.editorOptions = {
                            items: getJsonDataRespuestas(pregunta).map(function (objeto) {
                                return objeto.text;
                            }),
                            layout: "horizontal",
                            change: function (e) {
                                const selectedItem = e.sender.dataItem();
                                
                                Object.keys(selectedItem.actions).forEach(action => {
                                    if (selectedItem.actions[action]) {
                                        handleAction(action, selectedItem.actions[action]);
                                    }
                                });
                            }
                        };
                        break;
                    case pregunta.tipo === 'omultiple' && (pregunta.tipoDatoValidacion === 'text' || pregunta.tipoDatoValidacion == 'number') && pregunta.subTipo === 'lista-normal':
                        item.editor = "RadioGroup";
                        pregunta.kendoEditor = "RadioGroup";
                        item.editorOptions = {
                            items: getJsonDataRespuestas(pregunta).map(function (objeto) {
                                return {
                                    label: objeto.text,
                                    value: objeto.value,
                                    actions: objeto.actions
                                };
                            }),
                            layout: "horizontal",
                            change: function (e) {
                                const sender = e.sender;
                                const selectedIndex = $(sender.element).find('input:checked').index(); // Obtener el índice del item seleccionado basado en el input marcado
                                const selectedItem = sender._items[selectedIndex]; // Acceder al item seleccionado usando el índice
                                const actions = selectedItem.actions;

                                Object.keys(selectedItem.actions).forEach(action => {
                                    if (selectedItem.actions[action]) {
                                        handleAction(action, selectedItem.actions[action]);
                                    }
                                });
                            }
                        };
                        break;
                    case pregunta.tipo === 'omultiple' && pregunta.tipoDatoValidacion === 'text':
                        item.editor = "DropDownList";
                        pregunta.kendoEditor = "DropDownList";
                        item.editorOptions = {
                            dataSource: getJsonDataRespuestas(pregunta),
                            dataTextField: "text",
                            dataValueField: "value",
                            optionLabel: 'Selecciona una opción',
                            change: function (e) {
                                const selectedItem = e.sender.dataItem();

                                Object.keys(selectedItem.actions).forEach(action => {
                                    if (selectedItem.actions[action]) {
                                        handleAction(action, selectedItem.actions[action]);
                                    }
                                });
                            }
                        };
                        break;
                    case pregunta.tipoDatoValidacion === 'date':
                        item.editor = "DatePicker";
                        pregunta.kendoEditor = "DatePicker";
                        item.editorOptions = {
                            format: "dd-MM-yyyy",
                            change: function (e) {
                                const fieldId = this.element.attr('name'); // O 'id' si necesitas el ID
                                const value = kendo.toString(this.value(), "yyyy-MM-dd"); // Obtiene el valor del elemento

                                // Actualiza el valor del campo
                                updateFieldValue(fieldId, value);

                                //Obtenemos formulas que dependen del campo
                                evaluateFormulas(getDependentFormulas(fieldId));
                            }
                        }
                        break;
                    case pregunta.tipoDatoValidacion === 'datetime-local':
                        item.editor = "TimePicker";
                        pregunta.kendoEditor = "TimePicker";
                        break;
                    case pregunta.tipo === 'foto':
                        item.editor = function (container) {
                            $('<input name="files" type="file" class="k-upload" multiple="multiple">').appendTo(container);
                        };
                        pregunta.kendoEditor = "Foto";
                        break;
                    case pregunta.tipoDatoValidacion === 'slider':
                        item.editor = "Slider";
                        pregunta.kendoEditor = "Slider";
                        item.editorOptions = {
                            min: 0,
                            max: 100,
                            smallStep: 1,
                            largeStep: 10
                        };
                        break;
                    case pregunta.tipo === 'firma':
                        item.editor = function (container) {
                            $('<button type="button" class="btn btn-primary">Dibujar Firma</button>').appendTo(container).on('click', function () {
                                handleAction('abrirFirmaModal');
                            });
                        };
                        pregunta.kendoEditor = "Firma";
                        break;
                    case pregunta.tipoDatoValidacion === 'geopos':
                        item.editor = function (container) {
                            $('<button type="button" class="btn btn-primary">Seleccionar Ubicación</button>').appendTo(container).on('click', function () {
                                handleAction('abrirMapaModal');
                            });
                        };
                        pregunta.kendoEditor = "GeoPos";
                        break;
                    case pregunta.tipo === 'omatriz' && pregunta.tipoDatoValidacion === 'text':
                        createTable(pregunta, false); // Radiobuttons
                        break;
                    case pregunta.tipo === 'omatriz2' && pregunta.tipoDatoValidacion === 'text':
                        createTable(pregunta, true); // Checkboxes
                        break;
                    case pregunta.tipo === 'seccion':
                        item.editor = function (container) {
                            const responseContainerId = `container-response-cards-${pregunta.preguntaClave}`;


                            $('<button type="button" class="btn btn-primary">Agregar</button>')
                                .appendTo(container)
                                .on('click', function () {
                                    handleAction('abrirSeccionModal');

                                    // Filtrar las preguntas por la clave de sección correspondiente
                                    const jsonsss = JSON.parse(pregunta.JsonData);
                                    const filterData = formData.filter(preg => preg.seccionClave === jsonsss.seccionClave);

                                    // Crear el formulario de la subsección dinámica
                                    createSubsectionForm(filterData, responseContainerId);
                                });
                            $(`<div id="${responseContainerId}"></div>`).appendTo(container);
                        };
                        break;
                    case pregunta.tipoDatoValidacion === 'number':
                        item.editor = "NumericTextBox";
                        pregunta.kendoEditor = "NumericTextBox";
                        break;
                    case pregunta.tipoDatoValidacion === 'textarea':
                        item.editor = "TextArea";
                        pregunta.kendoEditor = "TextArea";
                        break;
                    default:
                        // Tipo de campo desconocido o no soportado
                        console.warn(`Tipo de campo no soportado: ${pregunta.tipo}`);
                        break;
                }

                if (!pregunta.activa) {
                    initItemsHiden.push(pregunta.preguntaClave);
                }
                // Valida si es requerido
                if (pregunta.validacionTipo != "") {
                    item.label = `${item.label} *`;
                    item.validation = { required: true, validationMessage: "Campo requerido" };
                }

                items.push(item);
            });
           
            return items;
        }

        function handleSubmit(event) {
            event.preventDefault();

            let allFormsValid = true;
            $('form.dinamic-form').each(function () {
                if (!checkIfSectionComplete($(this))) {
                    allFormsValid = false;
                    return false; // Salir del loop si algún formulario no es válido
                }
            });

            if (allFormsValid) {
                let dataQuestions = [];
                $('form.dinamic-form').each(function () {
                    const data = $(this).serializeArray();

                    data.forEach(item => {
                        const preguntaId = formData.find(q => q.preguntaClave === item.name)?.preguntaClave;

                        if (preguntaId !== undefined) {
                            dataQuestions.push({
                                PreguntaClave: preguntaId.toString(),
                                Respuesta: item.value
                            });
                        }
                    });
                });

                // Llamar al callback onSubmit si se especificó
                if (typeof settings.onSubmit === 'function') {
                    settings.onSubmit(dataQuestions);
                } else {
                    console.error('El callback onSubmit no está definido.');
                }
            } else {
                alert('Por favor, completa todos los campos requeridos.');
            }
        }

        function createForm(container, secciones) {
            container.empty(); // Limpiar el contenedor

            // Crear estructura HTML para las pestañas y contenido
            const colLgDiv = $('<div class="col-lg dinamic-form"></div>');
            const ulNav = $('<ul class="row nav tab-container"></ul>');
            const backgroundIndicator = $('<div class="background-indicator"></div>');
            ulNav.append(backgroundIndicator);

            const rowDiv = $('<div class="row mt-3" style="gap: 15px;flex-wrap: nowrap;"></div>');
            const cardDiv = $('<div class="card card-body p-0 tab-content" style="min-height: 60vh"></div>');

            secciones.forEach((seccion, index) => {
                const tabId = `tab-${index + 1}`;

                // Crear pestaña
                const liNavItem = $(`<li class="nav-item tab-item ${index === 0 ? 'item-start' : 'item-midle'} col p-0" data-section="${seccion.seccionClave}"></li>`);
                const aNavLink = $(`<a class="no-underline text-secondary tab-link ${index === 0 ? 'active' : ''}" data-bs-toggle="tab" href="#${tabId}"></a>`);
                const numberTab = $('<div class="number-tab"></div>').text(index + 1);
                aNavLink.append(numberTab).append(seccion.nombre);
                liNavItem.append(aNavLink);
                ulNav.append(liNavItem);

                // Crear contenido de la pestaña
                const tabPane = $(`<div class="form-section tab-pane ${index === 0 ? 'active' : ''}" id="${tabId}" role="tabpanel"></div>`);


                const formOptions = {
                    items: createQuestions(seccion.preguntas),
                    type: "group",
                    layout: "grid",
                    grid: { cols: 3, gutter: 15 },
                    buttonsTemplate: ""
                };

                const form = $('<form id="' + seccion.seccionClave + '" class="dinamic-form"></form>').kendoForm(formOptions);
                tabPane.append(form);
                forms.push({ id: seccion.seccionClave, form: form.data("kendoForm") });

                // Agregar botones de navegación
                const navButtons = $('<div class="form-navigation-buttons d-flex gap-3 justify-content-end"></div>');

                if (index > 0) {
                    const prevButton = $('<button type="button" class="btn btn-secondary">Anterior</button>');
                    prevButton.on('click', function () {
                        if (checkIfSectionComplete(form)) {
                            markSectionAsComplete(`#${tabId}`);
                        }
                        $(`#${tabId}`).removeClass('active');
                        $(`#tab-${index}`).addClass('active');
                        $(`.tab-link[href="#${tabId}"]`).removeClass('active');
                        $(`.tab-link[href="#tab-${index}"]`).click();
                        $(`.tab-link[href="#tab-${index}"]`).addClass('active');
                    });
                    navButtons.append(prevButton);
                }

                if (index < secciones.length - 1) {
                    const nextButton = $('<button type="button" class="btn btn-primary">Siguiente</button>');
                    nextButton.on('click', function () {
                        if (checkIfSectionComplete(form)) {
                            markSectionAsComplete(`#${tabId}`);
                        }
                        $(`#${tabId}`).removeClass('active');
                        $(`#tab-${index + 2}`).addClass('active');
                        $(`.tab-link[href="#${tabId}"]`).removeClass('active');
                        $(`.tab-link[href="#tab-${index + 2}"]`).click();
                        $(`.tab-link[href="#tab-${index + 2}"]`).addClass('active');
                    });
                    navButtons.append(nextButton);
                } else {
                    const saveButton = $('<button type="button" class="btn btn-success">Guardar</button>');
                    saveButton.on('click', handleSubmit);
                    navButtons.append(saveButton);
                }

                tabPane.append(navButtons);
                cardDiv.append(tabPane);
            });

            colLgDiv.append(ulNav);
            rowDiv.append(cardDiv);
            container.append(colLgDiv).append(rowDiv);

            // Inicializar los modales para dibujar firma y mapa
            initializeModals();

            // Inicializar validador en cada formulario
            container.find('form').each(function () {
                $(this).kendoValidator();
            });


            // Inicializar el mapa (Kendo Map) para seleccionar ubicación
            $('#mapaModal').on('shown.bs.modal', function () {
                initMapCanvas();
            });

            $('input[readonly], textarea[readonly], select[readonly]').css({
                'background-color': '#e0e0e0', // Color de fondo
                'color': '#620707',            // Color del texto
                'border-color': '#c0c0c0'      // Color del borde
            });
            
            const $activeTab = $("#formularioContainer").find(".tab-link.active").eq(0);
            const tabWidth = $activeTab.outerWidth();
            const tabOffsetLeft = $activeTab.position().left;
            backgroundIndicator.addClass('hide-before');

            backgroundIndicator.css({
                width: tabWidth + 'px',
                left: tabOffsetLeft + 'px'
            });

        }

        function initializeModals() {
            // Modal para dibujar firma
            const firmaModal = `
                    <div class="modal fade" id="firmaModal" tabindex="-1" aria-labelledby="firmaModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="firmaModalLabel">Dibujar Firma</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div id="firma-container">
                                        <div id="firmaCanvas" style="width: 100%; height: 100%;"></div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button id="resetSignature">Reiniciar Firma</button>
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                    <button type="button" class="btn btn-primary">Guardar Firma</button>
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            $('body').append(firmaModal);

            // Modal para seleccionar ubicación en mapa
            const mapaModal = `
                    <div class="modal fade" id="mapaModal" tabindex="-1" aria-labelledby="mapaModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="mapaModalLabel">Seleccionar Ubicación en Mapa</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div id="map"></div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                    <button type="button" class="btn btn-primary">Guardar Ubicación</button>
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            $('body').append(mapaModal);


            const seccionModal = `
                    <div class="modal fade" id="seccionModal" tabindex="-1" aria-labelledby="seccionModal" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="seccionModalLabel">Title</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div id="dynamicFormSubseccion"></div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                    <button type="button" class="btn btn-primary" id="guardar-subseccion">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            $('body').append(seccionModal);
        }

        function getJsonDataRespuestas(pregunta) {
            let respuestas = [];
            if (pregunta.jsonData && pregunta.jsonData != "{}") {
                try {
                    respuestas = JSON.parse(pregunta.jsonData).Respuestas.map(respuesta => ({
                        text: respuesta.txt,
                        value: respuesta.valor,
                        actions: {
                            esconder_preguntas: respuesta.esconder_preguntas,
                            mostrar_preguntas: respuesta.mostrar_preguntas,
                            esconder_secciones: respuesta.esconder_secciones,
                            mostrar_secciones: respuesta.mostrar_secciones,
                            mandarNotificacion: respuesta.mandarNotificacion,
                            abrirFirmaModal: respuesta.abrirFirmaModal,
                            abrirMapaModal: respuesta.abrirMapaModal
                        }
                    }));
                    return respuestas;
                } catch (error) {
                    console.error('Error al parsear jsonData:', error);
                }
            }
        }

        function getJsonDataTableData(pregunta) {
            let tableData = [];
            if (pregunta.JsonData && pregunta.JsonData != "{}") {
                try {
                    tableData = JSON.parse(pregunta.JsonData);
                    return tableData;
                } catch (error) {
                    console.error('Error al parsear jsonData:', error);
                }
            }
        }

        function createSubsectionForm(subsectionData, responseContainerId) {
            const formOptions = {
                items: createQuestions(subsectionData),
                type: "group",
                layout: "grid",
                grid: { cols: 3, gutter: 15 },
            };

            const form = $('<form id="dynamicSubseccionForm"></form>').kendoForm(formOptions);
            $("#dynamicFormSubseccion").empty().append(form);

            // Manejar evento de guardar la subsección
            $('#guardar-subseccion').off('click').on('click', function () {
                const validator = $('#dynamicSubseccionForm').data("kendoValidator");

                if (validator.validate()) {
                    const formData = {};
                    $('#dynamicSubseccionForm').find("input, select").each(function () {
                        formData[$(this).attr("name")] = $(this).val();
                    });

                    // Crear y agregar la card con las respuestas validadas
                    const cardHtml = createResponseCard(formData);
                    $(`#${responseContainerId}`).append(cardHtml);

                    $('#seccionModal').modal('hide');
                }
            });
        }

        function createResponseCard(formData) {
            const cardHtml = `
        <div class="card mt-3">
            <div class="card-body">
                <h5 class="card-title">Respuestas</h5>
                <ul class="list-unstyled">
                    ${Object.keys(formData).map(key => `<li><strong>${key}:</strong> ${formData[key]}</li>`).join('')}
                </ul>
            </div>
        </div>
    `;
            return cardHtml;
        }

        // FORMULAS
        // Función para manejar los cambios en los campos del formulario
        function handleFieldChange(event) {
            const fieldId = event.target.name; // CAMBIAR ID DE SER NECESARIO
            const value = event.target.value;
            // Actualiza el valor del campo
            updateFieldValue(fieldId, value);

            // Obtiene fórmulas que dependen del campo
            evaluateFormulas(getDependentFormulas(fieldId));
        }

        // Función para actualizar el valor de un campo en los datos
        function updateFieldValue(fieldId, value = null) {
            const formIndex = getFormIndexByFieldName(fieldId);
            if (formIndex !== -1) {
                if (value) {
                    data.fields[fieldId] = value;
                    return;
                }
                const fieldValue = getFieldValueByName(formIndex, fieldId);
                data.fields[fieldId] = fieldValue.trim();
            }
        }

        // Función para obtener el índice del formulario por un campo específico
        function getFormIndexByFieldName(fieldName) {
            for (let i = 0; i < forms.length; i++) {
                let items = forms[i].form.options.items;
                for (let j = 0; j < items.length; j++) {
                    if (items[j].field === fieldName) {
                        return i;
                    }
                }
            }
            return -1; // Si no se encuentra el formulario
        }

        // Función para obtener el valor de un campo por su nombre en un formulario específico
        function getFieldValueByName(formIndex, fieldName) {
            const form = forms[formIndex].form;
            if (form) {
                // Obtener el tipo de editor del campo
                const editorType = form.options.items.find(item => item.field === fieldName)?.editor;
                return $("#" + fieldName).data(`kendo${editorType}`).value();
            }
            return null; // Si no se encuentra el campo
        }

        // OBTIENE FORMULAS QUE DEPENDEN DEL CAMPO CAMBIADO
        function getDependentFormulas(fieldId) {
            var dependentFormulas = [];
            formData.forEach(function (pregunta) {
                if (pregunta?.formula?.includes(fieldId)) {
                    dependentFormulas.push({
                        input: pregunta.preguntaClave,
                        formula: pregunta.formula.trim(),
                        kendoEditor: pregunta.kendoEditor ? pregunta.kendoEditor : null,
                    });
                }
            });
            
            return dependentFormulas;
        }

        // VERIFICAREMOS SI TODOS LOS CAMPOS DE LA FORMULA ESTAN LLENOS PARA EVALUAR LA FORMULA
        function areAllFieldsFilled(requiredFields) {
            return requiredFields.every(function (fieldId) {
                return data.fields[fieldId] !== undefined && data.fields[fieldId] !== '';
            });
        }

        function evaluateFormulas(dependentFormulas) {
            dependentFormulas.forEach(function (inputData) {
                // Supongamos que tienes una función que evalúa fórmulas
                let requiredFields = extractRequiredFields(inputData.formula); // Extrae los campos necesarios

                if (areAllFieldsFilled(requiredFields)) {
                    const resultado = evaluarFormula(inputData.formula);
                    $("input[name='" + inputData.input + "']").val(resultado.replace(/['"\+\&]/g, ''));
                    $("#" + inputData.input).data(`kendo${inputData.kendoEditor}`).value(resultado.replace(/['"\+\&]/g, ''));
                }
            });
        }

        function extractRequiredFields(formula) {
            var fieldIds = [];
            var regex = /CAMPO\('([^']+)'\)|VALOR\('([^']+)'\)|CELDA\('([^']+)'\)/g;
            var match;

            while ((match = regex.exec(formula)) !== null) {
                if (match[1]) fieldIds.push(match[1]);
                if (match[2]) fieldIds.push(match[2]);
                if (match[3]) fieldIds.push(match[3]);
            }

            // Eliminar duplicados
            return [...new Set(fieldIds)];
        }

        // Funciones de reemplazo para obtener valores de claves directas
        const obtenerValor = (clave) => {
            return data.fields[clave] || clave;
        };

        // Función para evaluar fórmulas
        function evaluarFormula(formula) {
            // Reemplazo de funciones y campos específicos
            formula = formula.replace(/VALOR\('(.*?)'\)/g, (_, match) => valor(match));
            formula = formula.replace(/CAMPO\('(.*?)'\)/g, (_, match) => campo(match));

            // Reemplazar HOY en diferentes formatos
            formula = formula.replace(/HOY\('N'\)/g, `'${hoy('N')}'`);
            formula = formula.replace(/HOY\('U'\)/g, hoy('U'));
            formula = formula.replace(/HOY\('C'\)/g, hoy('C'));

            formula = formula.replace(/DAY\((.*?)\)/g, (_, match) => dayFunction(match));
            formula = formula.replace(/MONTH\((.*?)\)/g, (_, match) => monthFunction(match));
            formula = formula.replace(/YEAR\((.*?)\)/g, (_, match) => yearFunction(match));

            // Evaluación de funciones matemáticas y de fecha
            formula = formula.replace(/DATEVALUE\(([^)]+)\)/g, (match, p1) => {
                const dateValue = dateValueFunction(p1, data);
                return dateValue;
            });

            // Reemplazo inicial de claves directas
            formula = formula.replace(/\b([A-Z_]+)\b/g, (_, match) => obtenerValor(match));

            // Reemplazo de la función DIFFDIAS
            formula = formula.replace(/DIFFDIAS\(([^,]+),([^)]+)\)/g, (match, p1, p2) => {
                const diff = diffDias(p1, p2, data);
                return diff;
            });

            // Evaluar funciones complejas como ROUND, IF, OR, etc.
            let resultado;
            do {
                resultado = formula;
                formula = evaluarFuncionesComplejas(formula);
            } while (resultado !== formula);

            // Evaluar comparaciones booleanas y aritméticas
            formula = evaluarComparacionString(formula);

            // Evaluar funciones de concatenación
            formula = formula.replace(/CONCATENA\((.*?)\)/g, (_, match) => concatFunction(match));

            if (isArithmeticExpression(formula)) {
                try {
                    return eval(formula).toString().replace(/^'(.*)'$/, '$1');
                } catch (e) {
                    console.error("Error en la evaluación aritmética:", e);
                }
            }

            return formula.replace(/^'(.*)'$/, '$1');
        }

        function evaluarFuncionesComplejas(formula) {
            const functionRegex = /(ROUND|IF|SUMATABLA|SUMATABLAIF|SHOWIF|HIDEIF|OR|AND)\(([^()]*|(?:\([^()]*\))*)\)/g;

            return formula.replace(functionRegex, (match, functionName, content) => {
                const args = splitArguments(content, functionName === 'SUMATABLA' ? '+' : ',');

                switch (functionName.toUpperCase()) {
                    case 'ROUND':
                        return args.length === 2 ? round(evaluarFormula(args[0]), evaluarFormula(args[1])) : match;
                    case 'IF':
                        return args.length === 3 ? evaluarIF(evaluarFormula(args[0]), evaluarFormula(args[1]), evaluarFormula(args[2])) : match;
                    case 'OR':
                        return args.length >= 2 ? evaluarOR(args.map(x => evaluarFormula(x))) : match;
                    case 'AND':
                        return args.length >= 2 ? evaluarAND(args.map(x => evaluarFormula(x))) : match;
                    case 'SUMATABLA':
                        return args.length === 3 ? sumatabla(args.map(x => evaluarFormula(x))) : match;
                    case 'SUMATABLAIF':
                        return args.length === 3 ? sumatablaIf(evaluarFormula(args[0]), args[1], args[2]) : match;
                    case 'SHOWIF':
                        if (args.length === 2) {
                            showIf(evaluarFormula(args[0]), args[1]);
                            return '';
                        }
                        return match;
                    case 'HIDEIF':
                        if (args.length === 2) {
                            hideIf(evaluarFormula(args[0]), args[1]);
                            return '';
                        }
                        return match;
                    default:
                        return match;
                }
            });
        }

        function splitArguments(content, separator) {
            const args = [];
            let depth = 0;
            let current = '';

            for (let i = 0; i < content.length; i++) {
                const char = content[i];

                if (char === '(') {
                    depth++;
                } else if (char === ')') {
                    depth--;
                } else if (char === separator && depth === 0) {
                    args.push(current.trim());
                    current = '';
                    continue;
                }

                current += char;
            }

            if (current) {
                args.push(current.trim());
            }

            return args;
        }

        function evaluarOR(args) {
            return args.some(arg => arg === 'true' || arg === '1').toString();
        }

        function evaluarAND(args) {
            return args.every(arg => arg === 'true' || arg === '1').toString();
        }

        function isArithmeticExpression(str) {
            const arithmeticRegex = /^[\d+\-*/().\s]+$/;
            return arithmeticRegex.test(str);
        }

        // Funciones de utilidad
        function hoy(formato) {
            const now = new Date();
            if (formato === 'N') {
                return now.toISOString().split('T')[0];
            } else if (formato === 'U') {
                return now.toISOString();
            } else if (formato === 'C') {
                const year = now.getFullYear().toString().slice(-2);
                const month = (now.getMonth() + 1).toString().padStart(2, '0');
                const day = now.getDate().toString().padStart(2, '0');
                const hours = now.getHours().toString().padStart(2, '0');
                const minutes = now.getMinutes().toString().padStart(2, '0');
                const seconds = now.getSeconds().toString().padStart(2, '0');
                return `${year}${month}${day}_${hours}${minutes}${seconds}`;
            }
            return null;
        }

        function valor(campo) {
            return data.fields[campo] || '';
        }

        function campo(clave) {
            const valor = obtenerValor(clave);
            return typeof valor === 'string' ? `'${valor}'` : valor; // Añade comillas si es una cadena
        }

        function dayFunction(campo) {
            const fecha = new Date(campo);
            return fecha.getDate();
        }

        function monthFunction(campo) {
            const fecha = new Date(campo);
            return fecha.getMonth() + 1;
        }

        function yearFunction(campo) {
            const fecha = new Date(campo);
            return fecha.getFullYear();
        }

        function dateValueFunction(fechaStr, data) {
            let fecha;
            if (fechaStr.startsWith('CAMPO')) {
                const campo = fechaStr.match(/CAMPO\('([^']+)'\)/)[1];
                fecha = data.fields[campo];
            } else {
                fecha = fechaStr.replace(/'/g, '');
            }
            const fechaObj = new Date(fecha);
            if (isNaN(fechaObj.getTime())) {
                return 0;
            }
            const fechaReferencia = new Date('1900-01-01');
            const diffTime = fechaObj - fechaReferencia;
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1;
            return diffDays;
        }

        function diffDias(fechaInicioStr, fechaFinStr, data) {
            let fechaInicio, fechaFin;

            if (fechaInicioStr.startsWith('CAMPO')) {
                const campo = fechaInicioStr.match(/CAMPO\('([^']+)'\)/)[1];
                fechaInicio = data.fields[campo];
            } else {
                fechaInicio = fechaInicioStr.replace(/'/g, '');
            }

            if (fechaFinStr.startsWith('CAMPO')) {
                const campo = fechaFinStr.match(/CAMPO\('([^']+)'\)/)[1];
                fechaFin = data.fields[campo];
            } else {
                fechaFin = fechaFinStr.replace(/'/g, '');
            }

            const fecha1 = new Date(fechaInicio);
            const fecha2 = new Date(fechaFin);

            if (isNaN(fecha1.getTime()) || isNaN(fecha2.getTime())) {
                return 0;
            }

            const diffTime = fecha2 - fecha1;
            return Math.floor(diffTime / (1000 * 60 * 60 * 24));
        }

        // Función de concatenación de cadenas
        function concatFunction(match) {
            const parts = match.split(',');
            return parts.map(part => evaluarFormula(part)).join('');
        }

        // Evaluar fórmula IF
        function evaluarIF(condicion, verdadero, falso) {
            return condicion === 'true' ? verdadero : falso;
        }

        function evaluarComparacionString(str) {
            // Evaluar comparaciones de igualdad de cadenas
            const regex = /'(.*?)'\s*=\s*'(.*?)'/;
            const match = str.match(regex);

            if (match) {
                const [, part1, part2] = match;
                // Elimina espacios en blanco y compara
                return (part1.trim() === part2.trim()).toString();
            }

            return str;
        }

        // Evaluar fórmula ROUND
        function round(valor, decimales) {
            return parseFloat(valor).toFixed(parseInt(decimales, 10));
        }

        // Evaluar SUMATABLA
        function sumatabla(args) {
            return args.reduce((acc, val) => acc + parseFloat(val), 0);
        }

        // Evaluar SUMATABLAIF
        function sumatablaIf(condicion, rango1, rango2) {
            return (evaluarFormula(condicion) === 'true' ? sumatabla(rango1.split(',').map(x => evaluarFormula(x.trim()))) : 0);
        }

        // Funciones SHOWIF y HIDEIF
        function showIf(condicion, campo) {
            if (evaluarFormula(condicion) === 'true') {
                $(`#${campo}`).show();
            }
        }

        function hideIf(condicion, campo) {
            if (evaluarFormula(condicion) === 'true') {
                $(`#${campo}`).hide();
            }
        }


        // Ejemplo de inicialización de datos y eventos
        let data = {
            fields: {}, // Almacena los valores de los campos
            tables: {}, // Almacena datos de tablas
            cells: {}   // Almacena valores de celdas
        };


        return this.each(function () { 
            const $container = $(this);
            const secciones = getSecciones(formData);
            createForm($container, secciones);

            document.querySelectorAll('input, select, textarea').forEach(input => {
                input.addEventListener('change', handleFieldChange);
            });

            initItemsHiden.forEach(key => {
                initItemsHiden.forEach(key => {
                    handleAction('esconder_preguntas', key);
                });
            });
        });
    };
})(jQuery);
