// Javascript

import 'jquery';
//import 'jquery-validation';
//import 'jquery-validation-unobtrusive';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import * as _ from 'lodash';
window._ = _
import Swal from 'sweetalert2';
window.Swal = Swal
import * as Toastr from 'toastr'
window.Toastr = Toastr
import * as Validator from 'validatorjs';
window.Validator = Validator
import '../lib/kendo-ui/js/kendo.all.min';
import '../lib/kendo-ui/js/jszip.min'
KendoLicensing.setScriptKey('141j044b041h541j4i1d542e581e4i1i4g260i2k0f2e0c2a0029002a0d300j1e603j09214c1g52254c2b5j30572j541c4g265c435i3b5k3a58365d3658395h424i2h123d551g4c24511g572f562g614958315e345i3g5j3g5i335748532753314e3h4d074d052a434j3b5k3c5h3d4d414j4b56335h395g3c4i022d0f2k46514954414h493018270j3h2g434i1b3e07531f4d0e381a5j395j3a5j054e1f510e471854151i0544');
import * as XLSX from 'xlsx';
window.XLSX = XLSX;

// Styles 
import '../scss/main.scss';

// Customization
import http from '../lib/http'
window.http = http

import Fu from '@victoryoalli/fu.js'
window.fu = Fu;

import translate,{_t} from '../lib/translate'
window.translate=translate
window._t=_t
window.trans=_t
import Dialog from '../lib/dialog'
window.Dialog = Dialog
import form from '../lib/form'
window.form = form;
import Str from '../lib/str'
window.Str = Str;
import Alert from '../lib/alert'
window.Alert = Alert;
import loading from '../lib/loading'
window.loading = loading;

import axios from '../lib/axios'
window.axios = axios;

import PermissionManager from '../lib/PermissionManager'
window.pm = PermissionManager;

import showElements from '../lib/showElements'
window.showElements = showElements;

import Num from './num';
window.Num = Num;

import { enableTab, disableTab } from './tab';
window.enableTab = enableTab;
window.disableTab = disableTab;
import '../lib/utilities/filterComponent.js';
import '../lib/utilities/pdfViewer.js';
import '../lib/utilities/slider.js';
import '../lib/utilities/fileUploader.js';
import '../lib/utilities/dynamicForm.js';
import '../lib/utilities/fileUploadComponent';
import filtersContent from "../lib/filtersContent.js";

window.filtersContent = filtersContent;

import * as Constants from '../lib/dictionary.js';

window.Paises = Constants.Paises;
window.PaisesEnum = Constants.PaisesEnum;
window.Subramos = Constants.Subramos;
window.SubramosEnum = Constants.SubramosEnum;
window.TipoPersona = Constants.TipoPersona;
window.TipoPersonaEnum = Constants.TipoPersonaEnum;
window.EstatusCotizacion = Constants.EstatusCotizacion;
window.TipoSeguroEnum = Constants.TipoSeguroEnum;
window.GrupoSeguroPecuarioEnum = Constants.GrupoSeguroPecuarioEnum;
window.Constants = Constants;

kendo.culture('es-MX');