﻿(function ($) {
    $.fn.fileUploader = function (params) {
        var defaults = {
            title: 'Subir Archivo',
            icon: 'pg pg-upload',
            required: false,
            disabled: false,
            acceptedTypes: '*', // Tipos de archivos por defecto (todos)
            maxFiles: 1, // Número máximo de archivos permitidos
            inputTime: false, // Mostrar input de fecha y hora
            nombre: 'fileUploader', // Nombre del input file
            id: 'fileUploaderId', // ID del input file
            minDate: null, // Fecha mínima
            maxDate: null  // Fecha máxima
        };

        var options = $.extend({}, defaults, params);

        this.addClass('file-uploader-container');

        var timeHtml = options.inputTime ? `<input id="dateTimeFile" class="file-time" />` : '';

        this.html(`
                <div class="d-flex flex-column gap-1 align-items-center p-2 container-custom">
                  <label class="text-center label-title">
                    ${options.title} ${options.required ? '<span class="text-danger">*</span>' : ''}
                  </label>
                  <div class="icon-counter text-center w-50">
                    <i class="${options.icon}"></i>
                    <span class="file-count">0</span>
                  </div>
                  <div class="d-flex flex-column gap-1 align-items-center input-container">
                    <input type="file" class="file-input" id="${options.id}" name="${options.nombre}" accept="${options.acceptedTypes}" ${options.maxFiles > 1 ? 'multiple' : ''}>
                    <button type="button" class="btn-upload" ${options.disabled ? 'disabled' : ''}>
                      <span class="pg pg-upload pe-1"></span> Subir archivo
                    </button>
                    ${timeHtml}
                  </div>
                  <div class="text-danger validation-message">Campo requerido</div>
                </div>
            `);

        var container = this;
        var fileInput = this.find('.file-input');
        var fileLabel = this.find('.file-label'); // Para etiqueta de archivo si se necesita
        var btnUpload = this.find('.btn-upload');
        var fileCount = this.find('.file-count');
        var validationMessage = this.find('.validation-message');
        var fileTime = this.find('.file-time');

        btnUpload.click(function () {
            fileInput.click();
        });

        if (options.inputTime) {
            let datePickerOptions = {
                size: "small",
                fillMode: "flat",
                adaptiveMode: "auto"
            };
            if (options.minDate) {
                datePickerOptions.min = options.minDate;
            }
            if (options.maxDate) {
                datePickerOptions.max = options.maxDate;
            }
            $("#dateTimeFile").kendoDatePicker(datePickerOptions);
        }

        fileInput.change(function () {
            if (this.files && this.files.length) {
                var fileName = $(this).val().split('\\').pop();
                fileLabel.html(`<b>Archivo Seleccionado: ${fileName}</b>`);
                fileCount.text(`${this.files.length}`);
                validationMessage.hide();
            } else {
                fileLabel.html('');
                fileCount.text('0');
                if (options.required) {
                    validationMessage.show();
                }
            }
        });

        this.getData = function () {
            var files = fileInput[0].files;
            var dateTime = options.inputTime ? fileTime.val() : '';
            var nombre = fileInput.attr('name');
            var id = fileInput.attr('id');
            var valid = true;

            if (options.required && (files.length === 0 || dateTime === '')) {
                validationMessage.show();
                valid =false;
            }
            if (options.inputTime && files.length > 0 &&  dateTime === '') {
                validationMessage.show();
                valid = false;
            }

            return {
                files: files,
                nombre: nombre,
                id: id,
                dateTime: dateTime,
                valid: valid
            };
        };

        return this;
    };
})(jQuery);