﻿(function ($) {
    $.fn.initPDFViewer = function (pdfUrl, fileName) {
        // Definir las variables y los métodos internos
        let pdfDoc = null,
            pageNum = 1,
            pageRendering = false,
            pageNumPending = null,
            scale = 1.5,
            rotate = 0,
            container = this.get(0);

        // Método para cargar y renderizar el PDF
        function loadPDF() {
            loading().show();
            pdfjsLib.getDocument(pdfUrl).promise.then(function (pdf) {
                pdfDoc = pdf;
                $(container).find('#pageCount').text(pdf.numPages);
                renderPage(pageNum);

                // Obtener el nombre del archivo de la respuesta del servidor si no se proporciona uno explícitamente
                if (!fileName) {
                    fetch(pdfUrl, { method: 'HEAD' })
                        .then(response => {
                            const contentDisposition = response.headers.get('Content-Disposition');
                            const matches = /filename="([^"]+)"/.exec(contentDisposition);
                            if (matches) {
                                fileName = matches[1];
                                $(container).find('#fileName').text(fileName);
                            }
                        });
                }
                loading().hide();
            }).catch(function (error) {
                loading().hide();
                console.error('Error loading PDF:', error);
            });
        }

        // Método para renderizar una página
        function renderPage(num) {
            loading().show();
            pageRendering = true;
            $(container).find('#pdfContainer').empty();

            pdfDoc.getPage(num).then(function (page) {
                const viewport = page.getViewport({ scale: scale, rotation: rotate });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');

                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };

                const renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                    pageRendering = false;
                    if (pageNumPending !== null) {
                        renderPage(pageNumPending);
                        pageNumPending = null;
                    }
                });

                $(container).find('#pdfContainer').append(canvas);
                $(container).find('#pageNum').text(num);
                loading().hide();
            });
        }

        function queueRenderPage(num) {
            if (pageRendering) {
                pageNumPending = num;
            } else {
                renderPage(num);
            }
        }

        function updateZoomLevel() {
            const zoomPercentage = Math.round(scale * 100);
            $(container).find('#zoomLevel').text(`${zoomPercentage}%`);
        }

        function downloadPDF() {
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = fileName || 'download.pdf';
            a.click();
        } 
        // Definir los eventos
        function addEventListeners() {
            $(container).find('#zoomIn').on('click', function () {
                scale += 0.1;
                renderPage(pageNum);
                updateZoomLevel();
            });

            $(container).find('#zoomOut').on('click', function () {
                if (scale <= 0.5) {
                    return;
                }
                scale -= 0.1;
                renderPage(pageNum);
                updateZoomLevel();
            });

            $(container).find('#previousPage').on('click', function () {
                if (pageNum <= 1) {
                    return;
                }
                pageNum--;
                queueRenderPage(pageNum);
            });

            $(container).find('#nextPage').on('click', function () {
                if (pageNum >= pdfDoc.numPages) {
                    return;
                }
                pageNum++;
                queueRenderPage(pageNum);
            });

            $(container).find('#rotate').on('click', function () {
                rotate = (rotate + 90) % 360;
                renderPage(pageNum);
            });

            $(container).find('#print').on('click', function () {
                window.open(pdfUrl, '_blank');
            });

            $(container).find('#download').on('click', function () {
                downloadPDF();
            });
        }

        // Inicializar el componente
        this.addClass('pdf-viewer-container');
        this.html(`
            <div class="pg-pdf-controls">
                <div class="float-start">
                    <span id="fileName">${fileName || ''}</span>
                </div>
                <div class="d-flex justify-content-center align-items-center gap-1">
                    <div>
                        <button id="previousPage" class="pg-pdf-toolbar-icon-button"><span class="pg pg-caret-left caret"></span></button>
                        <span><span id="pageNum" class="pg-pdf-toolbar-input bg">1</span> / <span id="pageCount" class="pg-pdf-toolbar-input"></span></span>
                        <button id="nextPage" class="pg-pdf-toolbar-icon-button"><span class="pg pg-caret-rigth caret"></span></button>
                    </div>
                    <span class="pg-pdf-separator"></span>
                    <div class="pg-pdf-controls-zoom">
                        <button id="zoomOut" class="pg-pdf-toolbar-icon-button p-0"> - </button>
                        <span id="zoomLevel" class="pg-pdf-toolbar-input bg ms-1 me-1 ps-2 pe-2">100%</span>
                        <button id="zoomIn" class="pg-pdf-toolbar-icon-button p-0"><span>+</span></button>
                    </div>
                    <span class="pg-pdf-separator"></span>
                    <div>
                        <button id="rotate" class="pg-pdf-toolbar-icon-button pt-2"><span class="pg pg-rotate"></span></button>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button id="print" class="pg-pdf-toolbar-icon-button"><span class="pg pg-print"></span></button>
                    <button id="download" class="pg-pdf-toolbar-icon-button"><span class="pg pg-download"></span></button>
                </div>
            </div>
            <div id="pdfContainer"></div>
        `);

        // Cargar el PDF y agregar los eventos
        loadPDF();
        addEventListeners();

        // Permitir el encadenamiento de métodos
        return this;
    };
})(jQuery);
