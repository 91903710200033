// Usage:
// import filtersContent from './filtersContent.js';
// const filters = filtersContent('filtersContent');
// filters.getValues();

/**
 * Obtiene los valores de los filtros
 * @param {any} filtersContent Id del contenedor de los filtros
 * @returns {any} Objeto con las funciones getValues y cleanValues
 */
export default function filtersContent(filtersContent) {
    const filtersContainer = document.getElementById(filtersContent);

    return {
        /**
         * Esta función se encarga de obtener los valores de los filtros de búsqueda y devolverlos en formato de query string
         * @returns {string} Query string con los valores de los filtros
         */
        getValues: () => {
            let inputs = filtersContainer.querySelectorAll('input[type="text"], input[type="date"], input[type="number"]');
            let checkboxes = filtersContainer.querySelectorAll('input[type="checkbox"]')
            let selects = filtersContainer.querySelectorAll('select');
            let queryString = '';

            if (inputs) {
                inputs.forEach((input) => {
                    if (input.value) queryString += `${input.name}=${input.value}&`;
                });
            }

            if (checkboxes) {
                checkboxes.forEach((checkbox) => {
                    queryString += `${checkbox.name}=${checkbox.checked}&`;
                });
            }

            if (selects) {
                selects.forEach((select) => {
                    if (select.value) queryString += `${select.name}=${select.value}&`;
                });
            }

            if(queryString.endsWith('&'))
                queryString = queryString.slice(0, -1);

            return queryString;
        },
        /**
         * Esta función se encarga de limpiar los valores de los filtros
         */
        cleanValues: () => {
            let inputs = filtersContainer.querySelectorAll('input[type="text"], input[type="date"], input[type="number"]');
            let checkboxes = filtersContainer.querySelectorAll('input[type="checkbox"]')
            let selects = filtersContainer.querySelectorAll('select');

            if (inputs)
                inputs.forEach((input) => input.value = '');

            if(checkboxes)
                checkboxes.forEach((checkbox) => checkbox.checked = false);

            if(selects)
                selects.forEach((select) => select.value = '');
        },
        /**
         * Obtiene los valores de los filtros de búsqueda y los devuelve en formato de objeto para enviar por POST
         * @returns {Object} Objeto con los valores de los filtros
         */
        getObject: () => {
            let inputs = filtersContainer.querySelectorAll('input[type="text"], input[type="date"], input[type="number"]');
            let checkboxes = filtersContainer.querySelectorAll('input[type="checkbox"]');
            let selects = filtersContainer.querySelectorAll('select');
            let result = {};

            if (inputs) {
                inputs.forEach((input) => {
                    if (input.value) result[input.name] = input.value;
                });
            }

            if (checkboxes) {
                checkboxes.forEach((checkbox) => {
                    result[checkbox.name] = checkbox.checked;
                });
            }

            if (selects) {
                selects.forEach((select) => {
                    if (select.value) result[select.name] = select.value;
                });
            }

            return result;
        }
    };
};