﻿
export default class PermissionCookie {


    constructor() {
    }

    hasPermission(permission) {
        const cookies = decodeURIComponent(document.cookie);
        const globalPermissions = JSON.parse(cookies.replace('Permissions=', ''));
        let hasPermission = false;

        if (globalPermissions != null) {

            globalPermissions?.LoggedPermissions.forEach((item) => {
                if (permission === item.permissionCode)
                    hasPermission = true;
            });

            return hasPermission;
        }
        return null;
    }

    hasRole(role) {
        const cookies = decodeURIComponent(document.cookie);
         const globalPermissions = JSON.parse(cookies.replace('Permissions=', ''));

        if (globalPermissions != null) {
            return globalPermissions.LoggedRoleName === role;
        }
        return null;
    }
}