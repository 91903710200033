$(document).on('click', '.tab-link', function (e) {
    e.preventDefault();

    var $this = $(this);

    // Ignorar clics en pesta�as deshabilitadas
    if ($this.hasClass('disabled')) {
        return;
    }

    var $tabContainer = $this.closest('.tab-container');
    var $backgroundIndicator = $tabContainer.find('.background-indicator');
    var tabWidth = $this.outerWidth();
    var tabOffsetLeft = $this.offset().left - $tabContainer.offset().left;

    $backgroundIndicator.stop().animate({
        width: tabWidth + 'px',
        left: tabOffsetLeft + 'px'
    }, 250);

    $('.tab-link').removeClass('active');
    $this.addClass('active');

    var $tabs = $tabContainer.find('.tab-link');
    var index = $tabs.index($this);
    if (index === 0) {
        $backgroundIndicator.addClass('hide-before');
        $backgroundIndicator.removeClass('hide-after');
    } else if (index === $tabs.length - 1) {
        $backgroundIndicator.addClass('hide-after');
        $backgroundIndicator.removeClass('hide-before');
    } else {
        $backgroundIndicator.removeClass('hide-before hide-after');
    }
});

function initNumbertab() {

    var contador = 1;
    $(".tab-container").find("li:visible").each(function () {
        // Encontrar el div con la clase "number-tab" dentro del <li> y asignarle el valor del contador
        $(this).find('.number-tab').text(contador);
        contador++;
    });
}

export function enableTab(href) {
    $(`a[href="${href}"]`).removeClass('disabled');
}

export function disableTab(href) {
    $(`a[href="${href}"]`).addClass('disabled');
}

$(document).ready(function () {
    // Agregamos un evento de cambio para detectar cuando se selecciona una opci�n
    const $activeTab = $('.tab-link.active');

    const $tabContainer = $activeTab.closest('.tab-container');

    const $backgroundIndicator = $tabContainer.find('.background-indicator');

    $backgroundIndicator.addClass('hide-before');

    const tabWidth = $activeTab?.outerWidth();
    const tabOffsetLeft = $activeTab?.position()?.left;

    $backgroundIndicator.css({
        width: tabWidth + 'px',
        left: tabOffsetLeft + 'px'
    });

    initNumbertab();
    applyCustomDropdownStyles();
    $("select").on("change", function () {
        $(this).siblings(".k-dropdown-wrap").removeClass("select-selected");
        $(this).siblings(".k-dropdown-wrap").addClass("select-selected");
    });
});

function applyCustomDropdownStyles() {
    setTimeout(function () {
        $(".k-cmbox").each(function () {
            let dropdown = $(this).data("kendoDropDownList");

            if (dropdown) {
                dropdown.bind("change", function (e) {
                    dropdown.wrapper.find(".k-input-inner").css({
                        "background-color": "#E1FFD7",
                        "color": "#212529"
                    });
                });

                if (dropdown.value()) {
                    dropdown.wrapper.find(".k-input-inner").css({
                        "background-color": "#E1FFD7",
                        "color": "#212529"
                    });
                }
            }
        });
    },
        1000);
}


// Ejemplo de uso:
//disableTab('#tab-2');
//enableTab('#tab-2');