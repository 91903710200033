﻿export default class PermissionHttp {

    constructor() {
    }

    async hasPermission(permission) {
        let hasPermission = false;
        const httpClient = http();
        const permissions = await httpClient.get("/api/permissions");
        
        if (permissions != null) {

            permissions.loggedPermissions.forEach((item) => {
                if (permission === item.permissionCode)
                    hasPermission = true;
            });

            return hasPermission;
        }
        return null;
    }

    async hasRole(role) {
        const httpClient = http();
        const permissions = await httpClient.get("/api/permissions");
        let result;
        
        if (permissions != null) {
            if(Number.isInteger(role)){
                result = parseInt(permissions.loggedRoleId) === role;
            }
            else
                result = permissions.loggedRoleName === role;
        }
        return result;
    }
}


