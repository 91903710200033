// Usage:
//const result1 = new Str('1231231.32423423').toString(5);
class Num {
    constructor(num) {
        this.number = num;
    }

    toString(decimalPlaces = 2) {
        let numString = this.number + ''; // convertimos a un string
        let numFloat = parseFloat(numString.replace(/[^0-9\-.]/g, ''));

        if (isNaN(numFloat))
            return '';

        if (numFloat === 0)
            return '0';

        numString = '' + numFloat.toFixed(decimalPlaces);

        let numParts = numString.split('.'), regexp = /(\d+)(\d{3})/;

        while (regexp.test(numParts[0]))
            numParts[0] = numParts[0].replace(regexp, '$1' + ',' + '$2');

        return numParts.join('.');
    }

  
}

export default Num;