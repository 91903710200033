﻿$.fn.initFilterComponent = function (searchFunction, clearFunction) {
    this.addClass("filter-container");
    // Agregar los botones de "Buscar" y "Limpiar" al final de cada formulario dentro del contenedor
    this.children().each(function () {
        $(this).append(`
            <div class="form-group mb-3 col-12 contenBtnFilter">
                <button id="btnObtenerValores">Buscar</button>
                <button id="btnResetearFormulario">Limpiar</button>
            </div>
        `);
    });

    // Agregar el botón de "Filtro" fuera del contenedor
    this.after('<button id="open-filterContent">Filtro</button>');

    // Evento para mostrar/ocultar el contenedor al hacer clic en el botón "Filtro"
    const filterContent = this;
    let isContentVisible = false;
    $('#open-filterContent').click(function () {
        filterContent.slideToggle({
            duration: 400,
            start: function () {
                isContentVisible = !isContentVisible;
                const contentHeight = $(this).outerHeight();
                if (isContentVisible) {
                    $('#open-filterContent').stop().animate({ top: contentHeight - 10 }, 400); // Animación al abrir el contenido
                } else {
                    $('#open-filterContent').stop().animate({ top: 0 }, 400); // Animación al cerrar el contenido
                }
            }
        });
    });

    // Evento para buscar al hacer clic en el botón de búsqueda
    this.on('click', '#btnObtenerValores', function () {
        searchFunction();
    });

    // Evento para limpiar al hacer clic en el botón de limpiar
    this.on('click', '#btnResetearFormulario', function () {
        clearFunction();
    });


    // Detener la propagación del clic en los elementos internos para evitar que el contenedor se cierre al hacer clic en ellos
    this.on('click', '.btn', (event) => {
        event.stopPropagation();
    });

    // Cerrar el contenedor si se hace clic fuera de él
    $(document).on('click', (event) => {
        if (!$(event.target).closest(this).length && !$(event.target).is('#open-filterContent') && !$(event.target).closest('[class^="k-"]').length ||
            ($(event.target).hasClass('k-webkit') && $(event.target).hasClass('k-webkit123'))) {
            if (this.is(":visible") && isContentVisible) {
                this.slideToggle({
                    duration: 400,
                    start: function () {
                        $('#open-filterContent').stop().animate({ top: 0 }, 400);
                        isContentVisible = false;
                    }
                });
            }
        }
    });

    // Retornar el objeto para permitir encadenamiento de métodos
    return this;
};
