import Swal from "sweetalert2";
export default class Dialog {

    static _showDialog(customOptions) {
        const defaultOptions = {
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            focusCancel: true,
            ...customOptions
        };

        return new Promise((resolve, reject) => {
            Swal.fire({
                text: customOptions.text,
                ...defaultOptions,
                title: customOptions.title,
                confirmButtonText: customOptions.confirmText,
                cancelButtonText: customOptions.cancelText
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve();
                } else {
                    reject('User cancelled');
                }
            }).catch(error => {
                console.error('Error in dialog:', error);
                reject(error);
            });
        });
    }

    static delete(text, options = {}) {
        const deleteOptions = {
            title: `¿Confirma eliminar el registro ${text}?`,
            text: '',
            confirmText: 'Si',
            cancelText: 'No',
            ...options
        };

        return this._showDialog(deleteOptions);
    }

    static change(text, options = {}) {
        const changeOptions = {
            title: `¿Está seguro de cambiar el estatus de ${text}?`,
            text: '',
            confirmText: 'Si',
            cancelText: 'No',
            ...options
        };

        return this._showDialog(changeOptions);
    }

    static prompt(text, options = {}) {
        const promptOptions = {
            title: '',
            text: text,
            confirmText: 'Si',
            cancelText: 'No',
            ...options
        };

        return this._showDialog(promptOptions);
    }
}
