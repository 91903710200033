// Usage:
//const showManager = showElements();
//showManager.setValues({
//    pais: 'MEX',
//    subramo: 'agricola',
//    tipoSeguro: 'ACUICOLA'
//});
//// Actualiza din�micamente un valor espec�fico
//showManager.setValue('tipoSeguro', 'APICOLA');
/*
HTML:

<!-- Este elemento se mostrar� solo si pais es 'MEX' y subramo es 'agricola' o 'pecuario' -->
<div id="exampleElement1" data-showIF="pais[MEX] && subramo[agricola,pecuario]">
    Este es un ejemplo de elemento que se muestra basado en condiciones.
</div>

<!-- Este elemento se ocultar� si tipoSeguro es 'ACUICOLA' -->
<div id="exampleElement2" data-hideIF="tipoSeguro[ACUICOLA]">
    Este es un ejemplo de elemento que se oculta basado en condiciones.
</div>

<!-- Este elemento se mostrar� si 'pais' no es 'MEX' -->
<div id="element5" data-showIF="!pais[MEX]">
    Este contenido es visible porque 'pais' no es 'MEX'.
</div>

<!-- Este elemento se ocultar� si 'subramo' no es 'agricola' -->
<div id="element6" data-hideIF="!subramo[agricola]">
    Este contenido se oculta porque 'subramo' no es 'agricola'.
</div>

<!-- Este elemento se ocultar� si 'pais' es 'USA' o 'tipoSeguro' es 'DA�O_DIRECTO' -->
<div id="element3" data-hideIF="pais[USA] || tipoSeguro[DA�O_DIRECTO]">
    Este contenido se oculta porque 'pais' es 'USA' o 'tipoSeguro' es 'DA�O_DIRECTO'.
</div>

<!-- Este elemento se mostrar� si el subramo es agricola o elesquema es ACUICOLA y si es agricola seguroPPEBMaximaUnidad debe ser true -->
<div class="mb-3 col-sm-6 col-lg-4 col-xl-3 ppeb" id="ppebDiv" 
     data-showIF="subramo[agricola]||tipoSeguro[ACUICOLA]" 
     data-hideIF="subramo[agricola] && !seguroPPEBMaximaUnidad[true]">
</div>
*/
export default function showElements(containerId = '') {
    const container = containerId !== '' ? document.getElementById(containerId) : document;
    let values = {};
    let elements = [];

    function evaluateCondition(condition, values) {
        const isNegated = condition.startsWith('!');
        const [key, allowedValuesStr] = condition.replace(/^!+|!+$/g, '').split('[');
        const allowedValues = allowedValuesStr.slice(0, -1).split(',').map(v => v.trim());
        const currentValue = values[key];

        const result = allowedValues.some(value =>
            value.startsWith('!') ? value.slice(1) !== currentValue : value === currentValue
        );

        return isNegated ? !result : result;
    }

    function applyVisibility() {
        elements.forEach(element => {
            const showConditions = element.getAttribute('data-showIF')?.split('||') || [];
            const hideConditions = element.getAttribute('data-hideIF')?.split('||') || [];

            const shouldShow = showConditions.length === 0 || showConditions.some(condition =>
                condition.split('&&').every(c => evaluateCondition(c.trim(), values))
            );

            const shouldHide = hideConditions.some(condition =>
                condition.split('&&').every(c => evaluateCondition(c.trim(), values))
            );

            element.style.display = shouldShow && !shouldHide ? '' : 'none';
        });
    }

    function initializeElements() {
        elements = Array.from(container.querySelectorAll('[data-showIF], [data-hideIF]'));
    }

    // Inicializa los elementos autom�ticamente al crear la instancia
    initializeElements();

    return {
        setValues: newValues => { //Setea los valores pasados como parametro
            values = { ...newValues };
            applyVisibility();
        },
        setValue: (key, value) => { //A�ade o actualiza un valor
            values[key] = value;
            applyVisibility();
        },
        addValues: newValues => { //agrega varios valores
            values = { ...values, ...newValues };
            applyVisibility();
        },
        applyVisibility,
        initialize: () => { //Actualiza los elemetos agragados al dom
            initializeElements();
            applyVisibility();
        },
        getValues: () => ({ ...values })
    };
}

