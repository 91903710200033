import * as Toastr from 'toastr'
export default class Alert {

    static configureToastr() {
        Toastr.options.newestOnTop = true;
        Toastr.options.closeButton = true;
        Toastr.options.closeMethod = 'fadeOut';
        Toastr.options.closeDuration = 300;
        Toastr.options.closeEasing = 'swing';
    }

    static info(text = '', title = _t("master.info")) {
        this.configureToastr();
        Toastr.info(text, title);
    }

    static warning(text = '', title = _t("master.alerta")) {
        this.configureToastr();
        Toastr.warning(text, title);
    }

    static error(text = '', title = _t("master.error")) {
        this.configureToastr();
        Toastr.error(text, title);
    }

    static success(text = '', title = _t("master.exitoso")) {
        this.configureToastr();
        Toastr.success(text, title);
    }
}