export function _t(key, args, element=null) {
    try {
        args = (typeof args === 'string' && args !== undefined) ? args.split(","):"";
        key = (typeof key === 'string' && key !== undefined)? key: "";
        if (key == "") return "";
        //console.log("key",key,typeof key )
        key = (typeof key === 'string' && key !== undefined) ? key.toLowerCase() : "";

        let f = (typeof key === 'string' ) ? key.split('.')[0] : "";
        key = (typeof key === 'string' ) ? key.replace(f + ".", ""):"";
        let parts = key.split(".");
        let translation = "";

        translation = parts.reduce((obj, key) => obj[key], translations[f]);

        if (translation && element) {
            element.innerHTML = interpolate(translation,...args);
        }
        return translation;
    } catch (err) {
        console.error("Error: \n" + err);
    }
}
export default function translate() {
    // Find all elements with a "t-key" attribute
    const elements = document.querySelectorAll("[trans]");

    // Loop through the elements and replace their contents with the
    // corresponding translation from the JSON object
    elements.forEach((element) => {
        let key = element.getAttribute("trans");
        let args = element.getAttribute("t-args");
        _t( key, args, element);

    });
}
function interpolate(str, ...args) {
    return str.replace(/\{(\d+)\}/g, (match, index) => args[index]);
}

// Call the translate function when the page loads
window.onload = _t;