import permissionHttp from "./PermissionHttp";
import permissionLocal from "./PermissionCookie";
/**
 * Permission Manager Library (pm)
 *
 * This library provides a simple interface for managing and checking permissions and roles
 * within an application. It supports both HTTP and local modes for fetching permissions data.
 *
 * Usage:
 * ------
 *
 * Instantiating the Permission Manager:
 *
 * const pm = new pm(true); // Pass true for initializing in HTTP mode, false for local mode.
 *
 * The HTTP mode is intended for applications that fetch permissions data over a network,
 * while the local mode is designed for applications with permissions data available locally.
 *
 * Checking if a user has a specific role:
 *
 * let hasAdminDevRole = pm.hasRole('AdminDev'); // Pass a role name as a string
 * let hasRoleById = pm.hasRole(1); // Alternatively, pass a role ID as a number
 *
 * The hasRole function returns true if the user has the specified role, false otherwise.
 *
 * Checking if a user has specific permissions:
 *
 * let hasPerAdDevPermissions = pm.hasPermissions('PerAdDev'); // Pass the permission name as a string
 *
 * The hasPermissions function checks if the user has the specified permissions. It returns
 * true if the user has the permissions, false otherwise.
 *
 * Switching Source Mode:
 *
 * You can switch between HTTP and local modes on-the-fly using the switchSource method:
 *
 * pm.switchSource(true); // Switch to HTTP mode
 * pm.switchSource(false); // Switch to local mode
 *
 * This method allows dynamically changing the source of permissions data without needing to
 * re-instantiate the Permission Manager. This can be useful in scenarios where your application
 * needs to switch between online and offline modes.
 *
 * Notes:
 * ------
 * - The role and permission names/IDs passed to hasRole and hasPermissions should match those
 *   defined in your application's permission schema.
 * - Ensure that the library is properly initialized and the correct mode is set before calling
 *   hasRole or hasPermissions.
 * - The HTTP mode requires additional configuration for endpoint URLs and authentication
 *   which is not covered in this basic usage guide.
 */

class PermissionManager {
    constructor(useHttp = true) {
        this.permissionSource = useHttp ? new permissionHttp() : new permissionLocal();
    }

    async hasPermission(permission) {
        return await this.permissionSource.hasPermission(permission);
    }

    async hasRole(role) {
        return await this.permissionSource.hasRole(role);
    }

    // Optionally, if you need to switch the permission source at runtime
    switchSource(useHttp) {
        this.permissionSource = useHttp ? new permissionHttp() : new permissionLocal();
    }
}

export default PermissionManager;
