﻿(function ($) {
    // Define un nuevo plugin de jQuery llamado 'fileUploadComponent'
    $.fn.fileUploadComponent = function (options) {
        // Aquí se establecen las opciones por defecto del componente, que incluyen URL, ancho, título, nombre del parámetro y extensiones de archivos permitidas.
        const settings = $.extend({
            url: "", // URL a la que se enviarán los archivos
            width: "500px", // Ancho del modal
            title: "Subir archivo", // Título del modal
            paramName: "file", // Nombre del parámetro que se usará en la solicitud
            allowedExtensions: [".xlsx", ".xls"], // Extensiones permitidas para subir archivos
            multiple: false, // Si se permite subir múltiples archivos
            onSuccess: function () { }, // Callback para cuando la carga es exitosa
            onError: function () { }, // Callback para cuando hay un error en la carga
            onComplete: function () { } // Callback para cuando la carga se completa
        }, options);

        // Inicializa el componente en cada elemento seleccionado
        this.each(function () {
            const $container = $(this); // Se guarda el contenedor del plugin

            // Se genera el HTML del modal y sus elementos <button id="openModal">Upload File</button>
            const htmlContent = `
                
                <div id="fileUploadModal" style="display: none;">
                    <div class="demo-section">
                        <input type="file" name="kendoFileUpload" id="kendoFileUpload" />
                    </div>
                    <div class="w-100 d-flex justify-content-center">
                        <div id="loadingSpinner" style="display: none;"></div>
                    </div>
                    <div style="border-top: 1px solid rgb(229, 230, 232);" class="d-flex w-100 pt-3">
                        <div id="uploadResults" style="font-family: Roboto; font-size: 9px; font-weight: 500; line-height: 12.39px; text-align: left; width: 100%;">
                            <div>Resumen</div>
                            <div class="d-flex justify-content-between flex-column">
                                <div class="text-success"><strong>Insertados:</strong> <span id="insertedRecords"></span></div>
                                <div class="text-info"><strong>Modificados:</strong> <span id="modifiedRecords"></span></div>
                                <div class="text-danger"><strong>Errores:</strong> <span id="errors"></span> <a href="#" id="toggleErrorDetails">[Ver Detalles]</a></div>
                            </div>
                        </div>
                        <div id="fileInportButtons" class="justify-content-end align-items-start gap-2 w-100" style="display: none;">
                            <button id="closeButton" class="btn">Cerrar</button>
                            <button id="importButton" class="btn">Importar</button>
                        </div>
                    </div>
                    <div id="errorDetails" class="list-unstyled font-weight-light pt-3 mt-4" style="display: none; font-family: Roboto;font-size: 9px;font-weight: 500;line-height: 12.39px;text-align: left;color: #9095A0;border-top: 1px solid #E5E6E8;"></div>
                </div>
            `;

            // Agrega el contenido HTML al contenedor del plugin
            $container.html(htmlContent);

            const $modal = $container.find("#fileUploadModal"); // Encuentra el modal

            // Inicializa la ventana modal con opciones como tamaño y título
            $modal.kendoWindow({
                width: settings.width,
                title: settings.title,
                visible: false, // El modal no es visible por defecto
                actions: ["Close"] // Se añaden botones de acción
            });

            // Configura el botón para abrir el modal
            $container.find("#openModal").click(function () {
                $modal.data("kendoWindow").center().open(); // Centra y abre el modal
                // Oculta el área de resultados y spinner mientras se prepara el modal
                setTimeout(function () {
                    $modal.find("#uploadResults, #loadingSpinner").hide();
                    $modal.find("#dragDropArea, #buttonsArea").show();
                }, 200);
            });

            // Inicializa un loader para mostrar mientras se carga el archivo
            $modal.find('#loadingSpinner').kendoLoader({
                size: 'medium' // Tamaño del loader
            }).data("kendoLoader");

            // Define una plantilla para mostrar la información del archivo
            const fileTemplate = `
                <span class='k-progress'></span>
                <div class='file-wrapper w-100'>
                    <div class="fileUpload-document-info-container" style="display: grid; grid-template-columns: 60% 20% auto; font-family: Roboto;font-size: 9px;font-weight: 500;line-height: 12.39px;text-align: left;align-items: center;">
                        <div class='file-header-table'>Nombre</div>
                        <div class='file-header-table'>Tamaño</div>
                        <div class='file-header-table'>Actions</div>

                        <div class='file-heading file-name-heading d-flex align-items-center gap-2'>
                            <span class='pg pg-policies mr-4'></span> #=name#
                        </div>
                        <div class='file-heading file-size-heading'>
                            #=(size / 1024).toFixed(2)# KB
                        </div>

                        <strong class="k-upload-status">
                            <button type='button' class='k-upload-action'></button>
                            <button type='button' class='k-upload-action'></button>
                        </strong>
                    </div>
                </div>
            `;

            // Se configura el componente de carga de archivos (kendoUpload)
            $modal.find("#kendoFileUpload").kendoUpload({
                async: {
                    saveUrl: settings.url, // URL donde se enviará el archivo
                    autoUpload: false // Se establece en false para permitir al usuario iniciar la carga manualmente
                },
                localization: {
                    select: "Seleccionar archivo", // Texto del botón de selección de archivo
                    dropFilesHere: "-o- Arrastre su documento aquí", // Texto para el área de arrastre
                    uploadSelectedFiles: "Subir Archivos", // Texto del botón para subir archivos
                    clearSelectedFiles: "Limpiar" // Texto para limpiar la selección
                },
                multiple: settings.multiple, // Si se permiten múltiples archivos
                validation: {
                    allowedExtensions: settings.allowedExtensions // Extensiones permitidas
                },
                template: kendo.template(fileTemplate), // Se aplica la plantilla definida anteriormente
                // Maneja el evento cuando se selecciona un archivo
                select: function (e) {
                    $modal.find("#dragDropArea").hide(); // Oculta el área de arrastre
                    $modal.find("#uploadForm").show(); // Muestra el formulario de carga
                    $modal.find("#fileInportButtons").addClass("d-flex"); // Muestra los botones de importación
                    const fileName = e.files[0].name; // Obtiene el nombre del archivo
                    const fileSize = e.files[0].size; // Obtiene el tamaño del archivo
                    // Muestra información del archivo seleccionado
                    $modal.find("#uploadForm").append(`<p>Archivo: ${fileName} (${(fileSize / 1024).toFixed(2)} KB)</p>`);
                },
                // Maneja el evento cuando se inicia la carga
                upload: function (e) {
                    e.preventDefault();
                    e.data = { cotizacionId: settings.cotizacionId }; // Agrega datos adicionales a la carga
                    const files = e.files; // Obtiene los archivos seleccionados
                    const formData = new FormData(); // Crea un nuevo objeto FormData
                    formData.append(settings.paramName, files[0].rawFile); // Agrega el archivo al FormData

                    // Muestra el spinner de carga mientras se sube el archivo
                    $modal.find("#loadingSpinner").show();
                    $modal.find("#buttonsArea").hide(); // Oculta los botones mientras se carga

                    // Realiza la solicitud AJAX para subir el archivo
                    $.ajax({
                        url: settings.url, // URL a la que se enviará la solicitud
                        type: "POST", // Método de la solicitud
                        data: formData, // Datos que se enviarán
                        contentType: false, // Indica que no se enviarán datos JSON
                        processData: false, // Evita que jQuery procese los datos
                        success: function (response) {
                            settings.onSuccess(response); // Llama a la función de éxito
                            showUploadResults(response); // Muestra los resultados de la carga
                            $modal.find("#fileStatus" + e.files[0].uid).text("Done"); // Muestra estado "Hecho"
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            Alert.error(jqXHR.responseJSON.message); // Muestra un mensaje de error
                            settings.onError(jqXHR); // Llama a la función de error
                            $modal.find("#fileStatus" + e.files[0].uid).text("Error"); // Muestra estado "Error"
                        },
                        complete: function () {
                            $modal.find("#loadingSpinner").hide(); // Oculta el spinner después de completar
                            settings.onComplete(); // Llama a la función de finalización
                        }
                    });
                },
                // Maneja el evento cuando se completa la carga
                success: function (e) {
                    $modal.find("#fileStatus").text("Status: Completed");
                },
                error: function (e) {
                    $modal.find("#fileStatus").text("Status: Failed");
                },
                progress: function (e) {
                    $modal.find("#fileStatus").text("Status: Uploading " + e.percentComplete + "%");
                }
            });

            // Función para mostrar los resultados de la carga
            function showUploadResults(response) {
                // Maneja y muestra los resultados de la respuesta
                const { inserted, updated, errorRecords, errors } = response; // Desestructura la respuesta
                $modal.find("#insertedRecords").text(inserted); // Muestra los registros insertados
                $modal.find("#modifiedRecords").text(updated); // Muestra los registros modificados
                $modal.find("#errors").text(errors); // Muestra la cantidad de errores
                // Si hay errores, se preparan los detalles para mostrar
                if (errorRecords.length) {
                    let errorDetailsHtml = "<ul>"; // Inicializa una lista para errores
                    errorRecords.forEach(error => {
                        errorDetailsHtml += `<li>${error.error}</li>`; // Agrega cada error a la lista
                    });
                    errorDetailsHtml += "</ul>"; // Cierra la lista
                    $modal.find("#errorDetails").html(errorDetailsHtml); // Muestra los detalles de errores
                    $modal.find("#errorDetails").show(); // Muestra el contenedor de detalles de errores
                }
                $modal.find("#uploadResults").show(); // Muestra el área de resultados de carga
            }

            // Maneja el evento del botón "Cerrar"
            $modal.on("click", "#closeButton", function () {
                $modal.data("kendoWindow").close(); // Cierra el modal
            });

            // Maneja el evento del botón "Importar"
            $modal.on("click", "#importButton", function () {
                    $modal.find("#kendoFileUpload").data("kendoUpload").upload();
            });

            // Maneja el evento del enlace "Ver Detalles"
            $modal.on("click", "#toggleErrorDetails", function (e) {
                e.preventDefault(); // Previene el comportamiento por defecto del enlace
                $modal.find("#errorDetails").toggle(); // Alterna la visibilidad de los detalles de errores
            });
        });

        // Asegúrate de que el método open esté accesible desde el contexto externo
        this.open = function () {
            const $modal = $("#fileUploadModal");
            if ($modal.length) {
                $modal.data("kendoWindow").center().open();
                setTimeout(function () {
                    $modal.find("#uploadResults, #loadingSpinner").hide();
                    $modal.find("#dragDropArea, #buttonsArea").show();
                    $modal.find("#kendoFileUpload").data("kendoUpload").clearAllFiles();
                }, 100);
            }
        };

        return this;
    };
}(jQuery));



/* EJEMPLO DE USO */
//<div id="uploadContainer">
//        <button id="openUploadModal">Cargar Archivos</button>
//    </div>

//    <script>
//        $(document).ready(function () {
//            // Inicializa el componente de carga de archivos
//           let data = $("#uploadContainer").uploadFileModal({
//                url: "/ruta/del/servidor/para/carga", // Cambia esto a tu URL de carga
//                modalTitle: "Cargar Archivos",
//                fileTypes: ".csv,.xlsx", // Tipos de archivos permitidos
//                onComplete: function () {
//                    // Lógica a ejecutar cuando la carga se completa
//                    console.log("Carga completada.");
//                }
//            });

//            // Maneja el evento para abrir el modal
//            $("#openUploadModal").on("click", function () {
//                data.open(); // Abre el modal
//            });
//        });
//    </script>