// Usage:
//loading('botonId').start('tex');
//loading('botonId').stop();

/* let btnLoading = loading('botonId');
btnLoading.start('tex')
btnLoading.stop();*/
class ButtonLoading {
    static start(button, text) {
        button.dataset.originalText = button.innerHTML;
        button.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ' + text;
        button.disabled = true;
    }

    static stop(button) {
        button.innerHTML = button.dataset.originalText;
        button.disabled = false;
    }
}

class DivLoading {
    static start(renderToElement, message) {
        var mask = renderToElement.querySelector('.loading-mask');

        if (!mask) {
            var maskHTML = `
                <div class="loading-mask">
                    <div class="loading-mask-content p-4">
                        <span class="spinner-border spinner-border-sm text-success"></span>
                        <label class="loading-message"></label>
                    </div>
                </div>`;
            renderToElement.style.position = 'relative';
            renderToElement.insertAdjacentHTML('beforeend', maskHTML);
            mask = renderToElement.querySelector('.loading-mask');
        }

        mask.querySelector('.loading-message').innerHTML = message;
        mask.style.display = 'block';
    }

    static stop(renderToElement) {
        var mask = renderToElement.querySelector('.loading-mask');

        if (mask)
            mask.style.display = 'none';
    }      
}

class FormLoading {
    static start(renderToElement, message) {
        var btnSubmit = renderToElement.querySelector('button[type="submit"]');

        DivLoading.start(renderToElement, message);

        if (btnSubmit) {
            ButtonLoading.start(btnSubmit, message);
        }
    }

    static stop(renderToElement) {
        var btnSubmit = renderToElement.querySelector('button[type="submit"]');

        DivLoading.stop(renderToElement);

        if (btnSubmit) {
            ButtonLoading.stop(btnSubmit);
        }
    }
}
export default function loading(renderTo) {
    var renderToElement = (renderTo instanceof Object) ? renderTo : document.getElementById(renderTo);

    return {
        start: (text) => {
            switch (renderToElement.tagName) {
                case 'BUTTON':
                    ButtonLoading.start(renderToElement, text);
                    break;
                case 'FORM':
                    FormLoading.start(renderToElement, text);
                    break;
                case 'DIV':
                    DivLoading.start(renderToElement, text);
                    break;
                default:
                    console.error('Tipo de elemento no compatible para iniciar el efecto de carga.');
            }
        },
        stop: () => {
            switch (renderToElement.tagName) {
                case 'BUTTON':
                    ButtonLoading.stop(renderToElement);
                    break;
                case 'FORM':
                    FormLoading.stop(renderToElement);
                    break;
                case 'DIV':
                    DivLoading.stop(renderToElement);
                    break;
                default:
                    console.error('Tipo de elemento no compatible para detener el efecto de carga.');
            }
        },
        show: () => {
            const overlay = document.createElement('div');
            overlay.className = 'overlay';
            const spinner = document.createElement('div');
            spinner.className = 'spinner';
            spinner.innerHTML = '<div></div>';
            overlay.appendChild(spinner);
            document.body.appendChild(overlay);
        },
        hide: () => {
            const overlay = document.querySelector('.overlay');
            if (overlay) {
                document.body.removeChild(overlay);
            }
        }
    };
}




